import React, { useEffect, useRef, useState } from 'react'
import { Tabs, Tooltip, message } from 'antd';

import AdminPanel from './AdminPanel';
import { PickUp } from './Pickup';
import { Delivery } from './Delivery';
import InvoiceModal from '../InvoiceModal';
import { DesktopOutlined, MobileOutlined, EyeOutlined, CheckCircleFilled, StopFilled, ClockCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faTrash } from '@fortawesome/free-solid-svg-icons'

export default function CompletedOrderHolder() {

    const navigate = useNavigate();
    const myRef = useRef();

    const [data, setData] = useState([]);
    // const [subTotal, setSubtotal] = useState(0);

    useEffect(() => {
        loadOrders();
    }, []);

    const loadOrders = async () => {
        var user = JSON.parse(localStorage.getItem("user"));

        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order?status=2`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            setData(response.data.data);
            // setNoOfPages(response.data.payload.data.last_page);
            // setNoOfResult(response.data.payload.data.total);
            // setFrom(response.data.payload.data.from);
            // setTo(response.data.payload.data.to);
        }
    };

    const viewInvoice = async (id) => {
        try {
            myRef.current.childMethod(id);
        } catch (error) {
            // return { payload: [] };
        }
    }

    const items = data.map(((item, index) => {

        return (
            <tr key={index}>
                <td scope="row">{index + 1}</td>
                <td>{item.created_at.split(".")[0]}</td>
                <td>
                    {item.pickup_method == '2' ? "Delivery" : "Pickup"}
                </td>
                <td> ${item.sub_total.toFixed(2)}</td>
                <td>{item.delivery_fee.toFixed(2)} </td>
                <td>${item.grand_total.toFixed(2)}</td>
                <td>
                    <div style={{ textAlign: "center" }}>
                        {item.status == 0 ? <Tooltip title="Unpaid"><ExclamationCircleOutlined style={{ fontSize: '18px', color: 'red' }} className='status-border-danger' /></Tooltip> :
                            item.status == 1 ? <Tooltip title="Pending"><ClockCircleFilled style={{ fontSize: '18px', color: '#08c' }} /></Tooltip> :
                                item.status == 2 ? <Tooltip title="Completed"><CheckCircleFilled style={{ fontSize: '18px', color: "#52c41a" }} /></Tooltip> :
                                    item.status == 3 ? <Tooltip title="Cancel"><StopFilled style={{ fontSize: '18px', color: '#ef6062' }} />  </Tooltip> : <></>}
                    </div>
                </td>
                <td>
                    {
                        item.status != 0 ? <Tooltip title="View Invoice">
                            <Button className="btn-alt" size="sm" variant="none" onClick={() => viewInvoice(item.id)}><EyeOutlined style={{ fontSize: '18px', color: '#08c' }} /></Button>
                        </Tooltip> : null
                    }
                    <Tooltip title="Delete">
                        <Button className='btn-alt' size="sm" variant="none" onClick={() => { handleDelete(item.id) }}>
                            <FontAwesomeIcon icon={faTrash} className='text-danger' />
                        </Button>
                    </Tooltip>
                </td>
            </tr >
        )
    }))

    const handleDelete = async data => {
        const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/order/${data}`);
        //  response.data;
        // console.log(response.data)
        if (response.data) {
            message.success('Order deleted successful.');
            loadOrders();
        }
    }

    return (
        <>
            <InvoiceModal ref={myRef} modelTitle="Permit Parking" >
            </InvoiceModal>
            <div className='container mt-3 container-admin'>
                <div className='row'>
                    <div className='col-md-2'>
                        <AdminPanel />
                    </div>
                    <div className='col-md-10'>
                        <div className='d-flex justify-content-between'>
                            <div> <h2>Completed Orders</h2>
                                <p>Manage completed orders</p>
                            </div>
                            <div>
                                {/* <button className="btn btn-primary" onClick={() => { handleShow(true); reset(); setSelectedProductId(0); }}>Add Product</button> */}
                            </div>
                        </div>
                        {data.length > 0 ?
                            <>
                                <div className='row'>
                                    <table className="table cart-item-tbl">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Method</th>
                                                <th scope="col">Sub Total</th>
                                                <th scope="col">Delivery Fee</th>
                                                <th scope="col">Total</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items}
                                        </tbody>
                                    </table>
                                </div>
                            </> : <div className='row text-center'>
                                {/* <FontAwesomeIcon icon={faCartShopping} size='10x' className=' mb-3' /> */}
                                <h2>No Data!</h2>
                                <p>There is no data to show as competed order.</p>
                                {/* <div><Button onClick={onClickBackToShop} type="primary" danger>RETURN TO SHOP</Button></div> */}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
