import React from 'react'
import { Tabs, message } from 'antd';

import AdminPanel from './AdminPanel';
import { PickUp } from './Pickup';
import { Delivery } from './Delivery';

export default function PendingOrderHolder() {

    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        {
            key: '1',
            label: 'Pickup',
            children: <PickUp/>,
        },
        {
            key: '2',
            label: 'Delivery',
            children: <Delivery/>,
        }
    ];

    return (
        <div className='container mt-3 container-admin'>
            <div className='row'>
                <div className='col-md-2'>
                    <AdminPanel />
                </div>
                <div className='col-md-10'>
                    <div className='d-flex justify-content-between'>
                        <div> <h2>Pending Orders</h2>
                            <p>Manage pending orders form customers</p>
                        </div>
                        <div>
                            {/* <button className="btn btn-primary" onClick={() => { handleShow(true); reset(); setSelectedProductId(0); }}>Add Product</button> */}
                        </div>
                    </div>

                    <div className='row'>
                        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                    </div>
                </div>
            </div>
        </div>
    )
}
