import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { message } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash, faEnvelope } from '@fortawesome/free-solid-svg-icons'
// <FontAwesomeIcon icon={faPenToSquare} />

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import AdminPanel from './AdminPanel';

export default function Users() {
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({});

    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            setData(response.data.payload.data);
        }
    };

    const items = data.map(((item, index) => {

        var subTotal1 = item.quantity * item.price;
        // var net = subTotal + subTotal1;

        return (
            <tr key={index}>
                <td scope="row">{index + 1}</td>
                <td>{item.name}</td>
                <td> {item.email}</td>
                <td>
                    <Button className='btn-alt' size="sm" variant="none" onClick={() => { handleEdit(item) }}>
                        <FontAwesomeIcon icon={faPenToSquare} className='' />
                    </Button>
                    <Button className='btn-alt' size="sm" variant="none" onClick={() => { handleDelete(item.id) }}>
                        <FontAwesomeIcon icon={faTrash} className='text-danger' />
                    </Button>
                </td>
            </tr>)
    }))

    const handleEdit = data => {
        console.log(data.id);
        setValue('username', data.name);
        setValue('email', data.email);
        setSelectedUserId(data.id)
        setShow(true);
    }

    const handleDelete = async data => {
        const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/users/${data}`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            message.success('User deleted successful.');
            loadUsers();
        }
    }

    const onSubmit = async (data) => {
        console.log("selectedUserId :: ", selectedUserId);
        try {
            var response = {}
            var resMessage = "";
            data.userType = 2; // Admin
            // data.file = data.file[0];
            if (selectedUserId == 0) {
                response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/users`, data);
                resMessage = "User created successful.";
            } else {
                response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/users/${selectedUserId}`, data);
                resMessage = "User updated successful.";
            }
            // return response.data;
            console.log(response.data);
            if (response.data) {
                // alert("User created successfully");
                message.success(resMessage);
                setShow(false);
                reset();
                await loadUsers()
            }
        } catch (error) {
            return { payload: [] };
        }
    }

    return (
        <div className='container mt-3 container-admin'>
            <div className='row'>
                <div className='col-md-2'>
                    <AdminPanel />
                </div>
                <div className='col-md-10'>

                    <div className='d-flex justify-content-between'>
                        <div> <h2>Users</h2>
                            <p>Manage users function to admin</p>
                        </div>
                        <div><button className="btn btn-primary" onClick={() => { handleShow(true); reset(); setSelectedUserId(0) }}>Add User</button></div>
                    </div>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>User Form</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control type="name" placeholder="Enter user name" {...register("username", {
                                        required: true
                                    })}
                                        isInvalid={errors.username} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.username && errors.username.type === 'required' && <span role="alert">Please enter a username.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" {...register("email", {
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address"
                                        }
                                    })}
                                        isInvalid={errors.email} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email && errors.email.type === 'required' && <span role="alert">Please enter an email.</span>}
                                        {errors.email && errors.email.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {
                                    selectedUserId == 0 ?

                                        <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                            <Form.Label>Password *</Form.Label>
                                            <Form.Control
                                                size="sm"
                                                type="password"
                                                placeholder="Enter your password *"
                                                autoFocus
                                                {...register("password", {
                                                    // required: true,
                                                    pattern: {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                                        message: "invalid Password"
                                                    }
                                                })}
                                                isInvalid={errors.password} />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password && errors.password.type === 'required' && <span role="alert">Please enter an password.</span>}
                                                {errors.password && errors.password.type === 'pattern' && <span role="alert">Password must contain minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&#).</span>}

                                            </Form.Control.Feedback>
                                        </Form.Group> : null
                                }

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className='row'>
                        <table className="table cart-item-tbl">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Action</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
