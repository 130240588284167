import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { message } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
// <FontAwesomeIcon icon={faPenToSquare} />

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import AdminPanel from './AdminPanel';

export default function Products() {
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({});

    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [showQtyModal, setShowQtyModal] = useState(false);
    const [updatedQty, setUpdatedQty] = useState(0);
    const [selectedProductId, setSelectedProductId] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleCloseQty = () => setShowQtyModal(false);
    const handleShowQty = () => setShowQtyModal(true);

    useEffect(() => {
        loadProducts();
    }, []);

    const loadProducts = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            setData(response.data.payload.data.data);
        }
    };

    const items = data.map(((item, index) => {

        var subTotal1 = item.quantity * item.price;
        // var net = subTotal + subTotal1;

        return (
            <tr key={index}>
                <td scope="row">{index + 1}</td>
                <td>{item.name}</td>
                <td>
                    <img className="cart-thumb" src={`${process.env.REACT_APP_API_ENDPOINT_STORAGE}/${item.image}`} alt="Card image" />
                </td>
                <td> ${item.price.toFixed(2)}</td>
                {/* <td>{item.quantity}</td> */}
                <td>
                    <Button className='btn-alt' size="sm" variant="none" onClick={() => { handleEdit(item) }}>
                        <FontAwesomeIcon icon={faPenToSquare} className='' />
                    </Button>
                    <Button className='btn-alt' size="sm" variant="none" onClick={() => { handleDelete(item.id) }}>
                        <FontAwesomeIcon icon={faTrash} className='text-danger' />
                    </Button>
                    <Button className='btn-alt' size="sm" variant="none" onClick={() => { handleUpdateQty(item) }}>
                        <FontAwesomeIcon icon={faCirclePlus} className='text-blue' />
                    </Button>
                </td>
                {/* <td><button className="btn btn-danger" onClick={() => deleteItem(item.id)}>x</button></td> */}
            </tr>)
    }))

    const handleUpdateQty = data => {
        setShowQtyModal(true);
        console.log(data.quantity);
        setUpdatedQty(data.quantity);
        setSelectedProductId(data.id)
    }

    const handleEdit = data => {
        console.log(data);
        setValue('name', data.name);
        setValue('price', data.price);
        setValue('description', data.description);
        setValue('qty', data.quantity);

        setShow(true);
    }

    const handleDelete = async data => {
        const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/products/${data}`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            //setData(response.data.payload.data);
            message.success('Product deleted successful.');
            loadProducts();
        }
    }

    const onSubmit = async (data) => {
        console.log(data.file[0]);
        try {
            const formData = new FormData();
            formData.append('file', data.file[0]);
            formData.append('name', data.name);
            formData.append('description', data.description);
            formData.append('price', data.price);
            formData.append('quantity', data.qty);

            // data.file = data.file[0];
            var response = {};
            var resMessage = "";
            if (selectedProductId == 0) {
                response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/products/upload`, formData);
                resMessage = "Product created successful.";
            } else {
                response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/products/update/${selectedProductId}`, formData);
                resMessage = "Product updated successful.";
            }
            // return response.data;
            console.log(response.data);
            if (response.data) {
                message.success(resMessage);
                setShow(false);
                reset();
                loadProducts();
            }
        } catch (error) {
            return { payload: [] };
        }
    }

    const submitQty = async data => {
        var data = {
            quantity : updatedQty
        }

        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/products/update-qty/${selectedProductId}`, data);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            //setData(response.data.payload.data);
            setShowQtyModal(false);
            message.success('Product updated successful.');
            loadProducts();
        }
    }

    return (
        <div className='container mt-3 container-admin'>
            <div className='row'>
                <div className='col-md-2'>
                    <AdminPanel />
                </div>
                <div className='col-md-10'>
                    <div className='d-flex justify-content-between'>
                        <div> <h2>Products</h2>
                            <p>Manage products function to user</p>
                        </div>
                        <div><button className="btn btn-primary" onClick={() => { handleShow(true); reset(); setSelectedProductId(0); }}>Add Product</button></div>
                    </div>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Product Form</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control type="name" placeholder="Enter product name" {...register("name", {
                                        required: true
                                    })}
                                        isInvalid={errors.name} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name && errors.name.type === 'required' && <span role="alert">Please enter a product name.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Price</Form.Label>
                                    <Form.Control type="number" placeholder="0" {...register("price", {
                                        required: true
                                    })}
                                        isInvalid={errors.price} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.price && errors.price.type === 'required' && <span role="alert">Please enter a product price.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control type="number" min={0} placeholder="0" {...register("qty", {
                                        required: true
                                    })}
                                        isInvalid={errors.qty} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.qty && errors.qty.type === 'required' && <span role="alert">Please enter a product quantity.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Select product image</Form.Label>
                                    <Form.Control type="file" placeholder="Select product image" {...register("file", { required: true })} />
                                    {/* <Form.Text className="text-muted">
                                        We'll never share your email with anyone else.
                                    </Form.Text> */}
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Item Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} {...register("description", {
                                        required: true
                                    })}
                                        isInvalid={errors.description} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description && errors.description.type === 'required' && <span role="alert">Please enter a product price.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}
                                {/* <Button variant="primary" type="submit">
                            Submit
                        </Button> */}
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={showQtyModal} onHide={handleCloseQty}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Product Quantity Form</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control type="number" min={0} placeholder="0" value={updatedQty} onChange={e => setUpdatedQty(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        {/* {errors.qty && errors.qty.type === 'required' && <span role="alert">Please enter a product quantity.</span>} */}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseQty}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={submitQty}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className='row'>
                        <table className="table cart-item-tbl">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Image</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Action</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
