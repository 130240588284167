import { message } from 'antd';
import axios from 'axios';
import React from 'react'
import { Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Link, json, useNavigate } from "react-router-dom";

export const Login = () => {
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({});
    const navigate = useNavigate();

    const onSubmit = async (data) => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/login`, data);
            // return response.data;
            console.log(response.data);
            if (response.status === 200) {
                // alert("login successfully");
                var data = response.data.payload.data.user;
                var token = response.data.payload.data.token;
                var userType = data.userType;

                localStorage.setItem("user", JSON.stringify(data));
                localStorage.setItem("token", token);
                localStorage.setItem("isLogin", true);
                localStorage.setItem("userType", userType);

                message.success('login successfully.');

                if (userType == "1" || userType == "2") {
                    window.location = "/products";
                } else {
                    window.location = "/cart";
                }
            } else {
                message.error('Username or password is incorrect.');
            }
        } catch (error) {
            message.error('Username or password is incorrect.');
            return { payload: [] };
        }
    }

    return (
        <div className='container mb-5  pb-5'>
            <div className='row text-center pt-4'>
                <h4 className='contact-h4'>LOGIN</h4>
                <h3 className='contact-h3'>Login to your account</h3>
            </div>
            <div className='pt-2 contact-container justify-content-center'>
                {/* <Form noValidate onSubmit={handleSubmit(onSubmit)}> */}
                <div className='col-md'>
                    <div className="">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                            {/* <Form.Label>Previous Password *</Form.Label> */}
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder="Enter your username"
                                autoFocus
                                {...register("username", {
                                    required: true
                                })}
                                isInvalid={errors.username} />
                            <Form.Control.Feedback type="invalid">
                                {errors.username && errors.username.type === 'required' && <span role="alert">Please enter an username.</span>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className='col-md'>
                    <div className="">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                            {/* <Form.Label>Previous Password *</Form.Label> */}
                            <Form.Control
                                size="sm"
                                type="password"
                                placeholder="Enter your password *"

                                {...register("password", {
                                    required: true,
                                    // pattern: {
                                    //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                    //     message: "invalid Password"
                                    // }
                                })}
                                isInvalid={errors.password} />
                            <Form.Control.Feedback type="invalid">
                                {errors.password && errors.password.type === 'required' && <span role="alert">Please enter an password.</span>}
                                {errors.password && errors.password.type === 'pattern' && <span role="alert">Password must contain minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&#).</span>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>

                <div className='col-md-12 mt-3 text-center'>
                    <Link to='/password-reset' className=''>Forgot Password</Link> &nbsp;
                    <button href="#" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>LOGIN</button>
                </div>
                {/* </Form> */}
            </div>
        </div>
    )
}
