import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { message } from 'antd';

function PasswordHolder({ user }) {
    const [userId, setUserId] = useState(0)

    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({});
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/users/password/${userId}`, data);
            // return response.data;
            console.log(response.data);
            if (response.data) {
                message.success('Password updated successful.');
            }
        } catch (error) {
            return { payload: [] };
        }
    }

    useEffect(() => {
        console.log(user)
        if (user) {
            setUserId(user.id)
        }
    }, [user]);

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className='col-md-6'>
            <div className='col-md-12'>
                <div className="">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                        <Form.Label>Old Password *</Form.Label>
                        <Form.Control
                            size="sm"
                            type="password"
                            placeholder="Enter your password *"
                            autoFocus
                            {...register("old_password", {
                                required: true,

                            })}
                            isInvalid={errors.old_password} />
                        <Form.Control.Feedback type="invalid">
                            {errors.old_password && errors.old_password.type === 'required' && <span role="alert">Please enter an old password.</span>}
                            {/* {errors.old_password && errors.old_password.type === 'pattern' && <span role="alert">Password must contain minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&#).</span>} */}

                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
            </div>
            <div className='col-md-12'>
                <div className="">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                        <Form.Label>New Password *</Form.Label>
                        <Form.Control
                            size="sm"
                            type="password"
                            placeholder="Enter your password *"
                            autoFocus
                            {...register("password", {
                                required: true,
                                pattern: {
                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                    message: "invalid Password"
                                }
                            })}
                            isInvalid={errors.password} />
                        <Form.Control.Feedback type="invalid">
                            {errors.password && errors.password.type === 'required' && <span role="alert">Please enter an password.</span>}
                            {errors.password && errors.password.type === 'pattern' && <span role="alert">Password must contain minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&#).</span>}

                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
            </div>


            <div className='col-md-12 mt-2'>
                <div className="">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                        <Form.Label>Confirm Password *</Form.Label>
                        <Form.Control
                            size="sm"
                            type="password"
                            placeholder="Enter your confirm password *"
                            autoFocus
                            {...register("confirmPassword", {
                                validate: (val) => {
                                    if (watch('password') !== val) {
                                        return "Your passwords do no match";
                                    }
                                }
                            })}
                            isInvalid={errors.confirmPassword} />
                        <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword && <span role="alert">Repeat password does not match..</span>}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
            </div>

            <div className='col-md-12 mt-3 text-right'>
                <button type='submit' className="btn btn-primary" >Update Password</button>

            </div>
        </Form>
    )
}

export default PasswordHolder