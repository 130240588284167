import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { message } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash, faEnvelope } from '@fortawesome/free-solid-svg-icons'
// <FontAwesomeIcon icon={faPenToSquare} />

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import AdminPanel from './AdminPanel';

export default function ContactMessages() {
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({});

    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/contact`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            setData(response.data.payload.data);
        }
    };

    const items = data.map(((item, index) => {

        var subTotal1 = item.quantity * item.price;
        // var net = subTotal + subTotal1;

        return (
            <tr key={index}>
                <td scope="row">{index + 1}</td>
                <td>{item.name}</td>
                <td> {item.email}</td>
                <td> {item.message}</td>
                <td>
                    {/* <Button className='btn-alt' size="sm" variant="none" onClick={() => { handleEdit(item) }}>
                        <FontAwesomeIcon icon={faPenToSquare} className='' />
                    </Button> */}
                    <Button className='btn-alt' size="sm" variant="none" onClick={() => { handleDelete(item.id) }}>
                        <FontAwesomeIcon icon={faTrash} className='text-danger' />
                    </Button>
                </td>
            </tr>)
    }))

    const handleEdit = data => {
        console.log(data);
        setValue('username', data.name);
        setValue('email', data.email);

        setShow(true);
    }

    const handleDelete = async data => {
        const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/contact/${data}`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            message.success('Contact message deleted successful.');
            loadUsers();
        }
    }

    const onSubmit = async (data) => {
        // console.log(data.file[0]);
        try {
            // const formData = new FormData();
            // formData.append('file', data.file[0]);
            // formData.append('name', data.name);
            // formData.append('description', data.description);
            // formData.append('price', data.price);


            // data.file = data.file[0];
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/products/upload`, data);
            // return response.data;
            console.log(response.data);
            if (response.data) {
                alert("User created successfully");
                message.success('User created successful.');
                setShow(false);
                reset();

            }
        } catch (error) {
            return { payload: [] };
        }
    }

    return (
        <div className='container mt-3 container-admin'>
            <div className='row'>
                <div className='col-md-2'>
                    <AdminPanel />
                </div>
                <div className='col-md-10'>

                    <div className='d-flex justify-content-between'>
                        <div>
                            <h2>Contact Messages</h2>
                            <p>Manage contact messages function to admin</p>
                        </div>
                        {/* <div><button className="btn btn-primary" onClick={() => { handleShow(true); reset(); }}>Add User</button></div> */}
                    </div>

                    <div className='row'>
                        <table className="table cart-item-tbl">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Message</th>
                                    <th scope="col">Action</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
