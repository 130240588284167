import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { Tooltip, message } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash, faEnvelope } from '@fortawesome/free-solid-svg-icons'
// <FontAwesomeIcon icon={faPenToSquare} />
import Select from "react-select";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from 'react-hook-form';
import AdminPanel from './AdminPanel';

export default function Promotion() {
    const { register, handleSubmit, formState: { errors }, reset, setValue, control } = useForm({});

    const [data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [products, setProducts] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        loadPromotions();
        loadProducts();
    }, []);

    const loadPromotions = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/promotion`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            setData(response.data.payload.data);
        }
    };

    const loadProducts = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products/get-select`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            setProducts(response.data.data);
        }
    };

    const items = data.map(((item, index) => {

        var subTotal1 = item.quantity * item.price;
        // var net = subTotal + subTotal1;

        return (
            <tr key={index}>
                <td scope="row">{index + 1}</td>
                <td>{item.name}</td>
                <td> {item.description}</td>
                <td>{item.start_date}</td>
                <td> {item.end_date}</td>
                <td>{item.discount_type}</td>
                <td> {item.discount_value}</td>
                <td>
                    <Tooltip title="Edit">
                        <Button className='btn-alt' size="sm" variant="none" onClick={() => { handleEdit(item) }}>
                            <FontAwesomeIcon icon={faPenToSquare} className='' />
                        </Button>
                    </Tooltip>

                    <Tooltip title="Delete">
                        <Button className='btn-alt' size="sm" variant="none" onClick={() => { handleDelete(item.id) }}>
                            <FontAwesomeIcon icon={faTrash} className='text-danger' />
                        </Button>
                    </Tooltip>
                </td>
            </tr>)
    }))

    const handleEdit = data => {
        console.log(data.id);
        setValue('name', data.name);
        setValue('description', data.description);
        setValue('startDate', data.start_date);
        setValue('endDate', data.end_date);
        setValue('discountType', data.discount_type);
        setValue('discountValue', data.discount_value);
        setValue('products', data.products);

        setSelectedUserId(data.id)
        setShow(true);
    }

    const handleDelete = async data => {
        const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/promotion/${data}`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            message.success('User deleted successful.');
            loadPromotions();
        }
    }

    const onSubmit = async (data) => {
        // console.log("selectedUserId :: ", data); return;
        try {
            var response = {}
            var resMessage = "";
            data.userType = 2; // Admin
            // data.file = data.file[0];
            if (selectedUserId == 0) {
                response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/promotion`, data);
                resMessage = "Promotion created successful.";
            } else {
                response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/promotion/${selectedUserId}`, data);
                resMessage = "Promotion updated successful.";
            }
            // return response.data;
            console.log(response.data);
            if (response.data) {
                // alert("User created successfully");
                message.success(resMessage);
                setShow(false);
                reset();
                await loadPromotions()
            } else {
                message.error("Error while saving promotion");
            }
        } catch (error) {
            console.log("goes to  error")
            message.error("Error while saving promotion");
            return { payload: [] };
        }
    }

    return (
        <div className='container mt-3 container-admin'>
            <div className='row'>
                <div className='col-md-2'>
                    <AdminPanel />
                </div>
                <div className='col-md-10'>

                    <div className='d-flex justify-content-between'>
                        <div> <h2>Promotions</h2>
                            <p>Manage promotions function to admin</p>
                        </div>
                        <div><button className="btn btn-primary" onClick={() => { handleShow(true); reset(); setSelectedUserId(0) }}>Add Promotion</button></div>
                    </div>

                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Promotion Form</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Promotion Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter promotion name" {...register("name", {
                                        required: true
                                    })}
                                        isInvalid={errors.name} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name && errors.name.type === 'required' && <span role="alert">Please enter a name.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" type="text" placeholder="Enter promotion description" {...register("description", {
                                        required: true,
                                        // pattern: {
                                        //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        //     message: "invalid description address"
                                        // }
                                    })}
                                        isInvalid={errors.description} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description && errors.description.type === 'required' && <span role="alert">Please enter an description.</span>}
                                        {errors.description && errors.description.type === 'pattern' && <span role="alert">Please enter a valid description.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control type="date" placeholder="Enter user name" {...register("startDate", {
                                        required: true
                                    })}
                                        isInvalid={errors.startDate} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.startDate && errors.startDate.type === 'required' && <span role="alert">Please enter a start date.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control type="date" placeholder="Enter user name" {...register("endDate", {
                                        required: true
                                    })}
                                        isInvalid={errors.endDate} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.endDate && errors.endDate.type === 'required' && <span role="alert">Please enter a end date.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Discount Type <span className='label-danger'>*</span></Form.Label>
                                    <Form.Select
                                        size="sm"
                                        {...register("discountType", { required: true })}
                                    >
                                        <option value="Percentage">Percentage</option>
                                        <option value="Value">Value</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Discount value</Form.Label>
                                    <Form.Control type="number" placeholder="Enter discount value" {...register("discountValue", {
                                        required: true
                                    })}
                                        isInvalid={errors.discountValue} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.discountValue && errors.discountValue.type === 'required' && <span role="alert">Please enter a discount value.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group
                                    className="mb-3"
                                    controlId="exampleForm.ControlTextarea1"
                                >
                                    <Form.Label>Products </Form.Label>
                                    <Controller
                                        name={"products"}
                                        control={control}
                                        rules={{ required: 'Please select a products' }}
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                placeholder="Select Products"
                                                options={products}
                                                isMulti
                                                onChange={e => { onChange(e) }}
                                                value={value}
                                                closeMenuOnSelect={false}
                                            />
                                        )}
                                    />
                                    {errors.products && <p className='error-msg'>{errors.products.message}</p>}

                                    {/* <Select
                            options={userGroups}
                            value={selectedUserGroupOptions}
                            onChange={setSelectedUserGroupOptions}
                            isMulti
                        /> */}
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSubmit(onSubmit)}>
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <div className='row'>
                        <table className="table cart-item-tbl-alt">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">description</th>
                                    <th scope="col">Start Date</th>
                                    <th scope="col">End Date</th>
                                    <th scope="col">Discount Type</th>
                                    <th scope="col">Discount Value</th>
                                    <th scope="col">Action</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
