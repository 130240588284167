import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { Card, message } from 'antd';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Invoice from '../common/Invoice';

export const Payment = () => {
    const [data, setData] = useState({});
    const [total, setTotal] = useState(0);

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({});
    const navigate = useNavigate();
    let { order_id } = useParams();

    useEffect(() => {
        loadCartItem();
    }, []);

    const loadCartItem = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order/${order_id}`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            setData(response.data.data);
            setTotal(response.data.data.grand_total);
            console.log("GRAND TOTAL ::: " + response.data.data.grand_total);
        }
    };

    const createOrder = (data, actions) => {
        // console.log("TOTAL ORDER :::::::::: "+total);
        return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
                {
                    description: "Your description",
                    amount: {
                        // currency_code: "AUD",
                        value: total,
                    },
                },
            ],
        });
    }

    const onApprove = async (data2, actions) => {
        const order = await actions.order.capture();
        // setPaid(true);
        console.log(order);
        if (order.status === "COMPLETED") {
            var data1 = { status: 1 };
            console.log("ORDER ID  :: ", order_id);
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/order/${order_id}`, data1);
            // return response.data;
            console.log(response.data);
            if (response.data) {
                await saveCartItem();
                navigate(`/order-success/${order_id}?pickup_method=${data?.pickup_method}&order_id=${order_id}`);
                // reset();
            } else {
                message.error('Error while saving order message.');
                // reset();
            }
        }
    }

    const saveCartItem = async () => {
        // console.log(total); return;
        try {
            var cartItem = JSON.parse(localStorage.getItem("cart_item"));
            var newCartItem = cartItem.map(item => {
                return {
                    'product_id': item.id,
                    'quantity': item.selectedQty,
                    'order_id': order_id
                }
            });
            console.log(newCartItem);
            var formData = {
                catItem: newCartItem
            }
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/cart/bulk`, formData);
            // return response.data;
            console.log(response.data);
            if (response.data) {
                localStorage.setItem("cart_item", JSON.stringify([]));
                // navigate(`/order-success/${order_id}?pickup_method=${data?.pickup_method}&order_id=${order_id}`);
                // reset();
            } else {
                message.error('Error while sending message.');
                // reset();
            }
        } catch (error) {
            message.error('Error while sending message.');

        }
    }

    const onError = (err) => {
        console.error(err);
    }

    return (
        <>
            <div className=' shop-background'>
                <h1 className='store-text'>CHECKOUT</h1>
            </div>
            <div className='container pt-5 pb-5'>
                <div className='row'>

                </div>


                <div className='row pt-4'>
                    <div className='col-md-3'>
                        <Card title="Your Order" bordered={true}>
                            <table className='tbl-payment-detail'>
                                <tbody>
                                    <tr>
                                        <td className='text-muted'>
                                            Order number
                                        </td>
                                        <td className='text-end text-muted'>{order_id}</td>
                                    </tr>
                                    <tr className='line'>

                                        <td className='text-muted'>
                                            Sub Total
                                        </td>
                                        <td className='text-end text-primary'>AUD {data?.sub_total?.toFixed(2)}</td>
                                    </tr>
                                    {
                                        data?.pickup_method == 2 ? <tr className='line'>
                                            <td className='text-muted'>
                                                Delivery Fee
                                            </td>
                                            <td className='text-end text-primary'>AUD {data?.delivery_fee?.toFixed(2)}</td>
                                        </tr> : null
                                    }

                                    {
                                        data?.promo_eligibility ? <tr className='line'>
                                            <td className='text-muted '>
                                                Promotion Discount
                                            </td>
                                            <td className='text-end text-primary'>AUD {data?.promo_discounted_value?.toFixed(2)}</td>
                                        </tr> : null
                                    }

                                    
                                    <tr>
                                        <td className='fs-4'>
                                            Total
                                        </td>
                                        <td className='text-end text-primary fs-4'>AUD {data?.grand_total?.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card>
                    </div>
                    <div className='col-md-9'>
                        <p className='text-muted'>Thank you for your order, please click the button below to pay.</p>
                        <div style={{ width: 200 }}>
                            <PayPalScriptProvider options={{ clientId: "AfuVY6PulyjOSUCveh44gmtFOzqH75SAG3WJL3zlqfCy_nFVzn9Y6UoOMORS8A7GG1ORbeEK8WO4FUjm", currency: "AUD" }} >
                                <PayPalButtons
                                    style={{ layout: "horizontal" }}
                                    createOrder={createOrder}
                                    onApprove={onApprove}
                                    onError={onError}
                                    currency="AUD"
                                    forceReRender={[total]}
                                />
                            </PayPalScriptProvider>
                        </div>

                        {/* <button onClick={saveCartItem}>test</button> */}
                    </div>
                </div>

                {/* <Form>
                    <div className='row pt-4 contact-container'>

                        <div className='col-md-6'>
                            <div className="">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                    <Form.Control
                                        size=""
                                        type="text"
                                        placeholder="Enter your name *"
                                        autoFocus
                                        {...register("name", {
                                            required: true
                                        })}
                                        isInvalid={errors.name} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name && errors.name.type === 'required' && <span role="alert">Please enter an name.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                <Form.Control
                                    size=""
                                    type="text"
                                    placeholder="Enter your email *"
                                    autoFocus
                                    {...register("email", {
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address"
                                        }
                                    })}
                                    isInvalid={errors.email} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email && errors.email.type === 'required' && <span role="alert">Please enter an email.</span>}
                                    {errors.email && errors.email.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                        <div className='col-md-12 mt-3'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    {...register("message", {
                                        required: true
                                    })}
                                    isInvalid={errors.message}
                                    placeholder='Enter your message'
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.message && errors.message.type === 'required' && <span role="alert">Please enter a message.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                        <div className='col-md-12 mt-3 text-center'>
                            <button href="#" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>SEND MESSAGE</button>
                        </div>
                    </div>
                </Form> */}
            </div>
        </>
    )
}
