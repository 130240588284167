import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Button } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCartShopping } from '@fortawesome/free-solid-svg-icons'
import { message } from 'antd';

export const Cart = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    // const [subTotal, setSubtotal] = useState(0);

    useEffect(() => {
        loadCountries();
    }, []);

    const loadCountries = async () => {
        var newCartItem = JSON.parse(localStorage.getItem("cart_item"));
        setData(newCartItem);
    };

    const deleteItem = async (id) => {
        try {
            var newArr = data.filter(function (obj) {
                return obj.id !== id;
            });
            setData(newArr);
            localStorage.setItem("cart_item", JSON.stringify(newArr));
        } catch (error) {
            // return { payload: [] };
        }
    }

    const items = data?.map(((item, index) => {

        var subTotal1 = item.selectedQty * item.price;
        return (
            <tr key={index}>
                <td scope="row">{index + 1}</td>
                <td>{item.name}</td>
                <td>
                    <img className="cart-thumb" src={`${process.env.REACT_APP_API_ENDPOINT_STORAGE}/${item.image}`} alt="Card image" />
                </td>
                <td> ${item.price.toFixed(2)}</td>
                <td><input type='number' value={item.selectedQty} onChange={e => onChangeItemQty(e.target.value, item.id, index)} min={1}></input></td>
                <td>${subTotal1.toFixed(2)}</td>
                <td><button className="btn btn-danger" onClick={() => deleteItem(item.id)}>x</button></td>
            </tr>
        )
    }))

    const onChangeItemQty = (qty, product_id, index) => {
        const updatedItems = data.map(item =>
            item.id === product_id ? { ...item, selectedQty: Number(qty) } : item
        );
        setData(updatedItems);
        localStorage.setItem("cart_item", JSON.stringify(updatedItems));
    }

    const totalChild = () => {
        var tot = data.reduce((accum, item) => accum + (item.price * item.selectedQty), 0);
        return tot.toFixed(2);
    }

    const onClickProceed = () => {
        var isLogin = localStorage.getItem("isLogin");

        if (isLogin == 'true') {
            navigate("/checkout");
        } else {
            message.info("Please login to the system before purchasing products.");
            navigate("/signin");
        }
    }

    const onClickBackToShop = () => {
        navigate("/shop");
    }

    return (
        <>
            <div className=' shop-background'>
                <h1 className='store-text'>Your Cart</h1>
            </div>
            <div className='container pt-5 pb-5'>
                {data?.length > 0 ?
                    <>
                        <div className='row'>
                            <table className="table cart-item-tbl">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Product</th>
                                        <th></th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Sub Total</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items}
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex justify-content-end pt-4  mr-auto '>
                            <div className='total-container'>
                                <h2>CART TOTALS &nbsp;</h2>
                                <table className="table sub-total-table" >
                                    <tbody>
                                        <tr>
                                            <th scope="col">SubTotal</th>
                                            <th scope="col">${totalChild()}</th>
                                        </tr>
                                        <tr>
                                            <th scope="col">Total</th>
                                            <th scope="col">${totalChild()}</th>
                                        </tr>
                                    </tbody>
                                </table>
                                <div><button className="btn btn-dark" onClick={onClickProceed}>Proceed to checkout</button></div>
                            </div>
                        </div>
                    </> : <div className='row text-center'>
                    <FontAwesomeIcon icon={faCartShopping} size='10x' className=' mb-3' />
                        <h2>Your Cart is Empty!</h2>
                        <p>Before proceed to checkout, you must add some product to your cart.</p>
                        <div><Button onClick={onClickBackToShop} type="primary" danger>RETURN TO SHOP</Button></div>
                    </div>
                }
            </div>
        </>
    )
}
