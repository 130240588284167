// import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom"

import { Header } from './common/Header';
import { Navigation } from './common/Navigation';
import { Footer } from './common/Footer';

import { Home } from './pages/Home';
import { Shop } from './pages/Shop';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { Cart } from './pages/Cart';
import { Register } from './pages/Register';
import { Login } from './pages/Login';
import Products from './pages/admin/Products';
import Users from './pages/admin/Users';
import TermsNCondition from './pages/TermsNCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ContactMessages from './pages/admin/ContactMessages';
import Profile from './pages/admin/Profile';
import Sale from './pages/Sale';
import  Checkout  from './pages/Checkout';
import { Payment } from './pages/Payment';
import OrderSuccess from './pages/OrderSuccess';
import { MyOrder } from './pages/MyOrder';
import PendingOrderHolder from './pages/admin/PendingOrderHolder';
import CompletedOrderHolder from './pages/admin/CompletedOrderHolder';
import Promotion from './pages/admin/Promotion';
import { PasswordReset } from './pages/PasswordReset';

function App() {
  return (
    <div className="App">
      <Header />
      <Navigation />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="shop" element={<Shop />} />
        <Route path="about-us" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="cart" element={<Cart />} />
        <Route path="signup" element={<Register />} />
        <Route path="signin" element={<Login />} />
        <Route path="products" element={<Products />} />
        <Route path="users" element={<Users />} />
        <Route path="terms_n_conditions" element={<TermsNCondition />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="contact-messages" element={<ContactMessages />} />
        <Route path="profile" element={<Profile />} />
        <Route path="sale" element={<Sale />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="payment/:order_id" element={<Payment />} />
        <Route path="order-success/:order_id" element={<OrderSuccess />} />
        <Route path="my-order" element={<MyOrder />} />
        <Route path="pending-order" element={<PendingOrderHolder />} />
        <Route path="completed-order" element={<CompletedOrderHolder />} />
        <Route path="promotion" element={<Promotion />} />
        <Route path="password-reset" element={<PasswordReset />} />
      
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
