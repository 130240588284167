import React from 'react'

const PrivacyPolicy = () => {
    return (
        <>
            <div className=' shop-background'>
                <h1 className='store-text'>Privacy Policy</h1>
            </div>
            <div className='container pt-5 pb-5'>
                <div className='row'>
                    Last Updated: <p>10<sup>th</sup> September 2023</p>
                    <ol>
                        <li> Introduction</li>

                        Chan sea food & veg store , we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our website, mobile applications, or interact with us through other means. By using our services, you consent to the practices described in this Privacy Policy.

                        <li> Information We Collect</li>

                        We may collect the following types of personal information:

                        Contact Information: Name, email address, phone number, and address.
                        Payment Information: Credit card details or other payment information for processing orders.
                        Account Information: Username, password, and other account details if you create an account on our website.
                        Purchase History: Information about products or services you have purchased from us.
                        Location Information: Information about your device's location if you enable location services.
                        Communications: Records of your correspondence with us, including emails, chat logs, and customer service interactions.
                        Website Usage: Information about your interactions with our website, including IP addresses, browser type, and usage patterns.
                        <li>3. How We Use Your Information</li>

                        We use your personal information for the following purposes:

                        To process and fulfill your orders.
                        To communicate with you about your orders, promotions, and updates.
                        To improve our website and services.
                        To respond to your inquiries and provide customer support.
                        To protect our rights, interests, and the security of our website.

                        <li>Sharing Your Information</li>

                        We may share your personal information with:

                        Service Providers: Third-party service providers who assist us with payment processing, shipping, and other business functions.
                        Legal Requirements: To comply with applicable laws, regulations, or legal requests.
                        We do not sell or rent your personal information to third parties for marketing purposes.

                        <li> Security</li>

                        We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.

                        <li> Your Choices</li>

                        You may have the following rights regarding your personal information:

                        Access: You can request access to the personal information we hold about you.
                        Correction: You can request corrections or updates to your personal information.
                        Deletion: You can request the deletion of your personal information, subject to legal requirements.
                        Marketing Preferences: You can opt out of receiving marketing communications from us.
                        <li> Changes to this Privacy Policy</li>

                        We may update this Privacy Policy to reflect changes in our practices or legal requirements. We will post the updated policy on our website.

                        <li> Contact Us</li>

                        If you have questions or concerns about this Privacy Policy or our data practices, please contact us at [(04)52218562.



                    </ol>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy