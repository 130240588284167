import { message } from 'antd';
import axios from 'axios';
import React from 'react'
import { Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { json, useNavigate } from "react-router-dom";

export const PasswordReset = () => {
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({});
    const navigate = useNavigate();

    const onSubmit = async (data) => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/users/password-reset`, data);
            // return response.data;
            console.log(response.data);
            if (response.status === 200) {
                // alert("login successfully");
                message.success('Your password has been rested successfully. Please check your email.');
                navigate("/signin");
            } else {
                message.error('Error while sending as email.');
            }
        } catch (error) {
            message.error('Error while sending as email.');
            return { payload: [] };
        }
    }

    return (
        <div className='container mb-5  pb-5'>
            <div className='row text-center pt-4'>
                <h4 className='contact-h4'>PASSWORD RESET</h4>
                <h6 className='contact-h3'>Please enter your email</h6>
            </div>
            <div className='pt-2 contact-container justify-content-center'>
                {/* <Form noValidate onSubmit={handleSubmit(onSubmit)}> */}
                <div className='col-md'>
                    <div className="">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder="Enter your email *"

                                {...register("email", {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid email address"
                                    }
                                })}
                                isInvalid={errors.email} />
                            <Form.Control.Feedback type="invalid">
                                {errors.email && errors.email.type === 'required' && <span role="alert">Please enter an email.</span>}
                                {errors.email && errors.email.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>

                <div className='col-md-12 mt-3 text-center'>
                    <button href="#" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>SUBMIT</button>
                </div>
                {/* </Form> */}
            </div>
        </div>
    )
}
