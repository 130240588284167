import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import axios from 'axios';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { message } from 'antd';

function ProfileHolder({ user }) {
    const [userId, setUserId] = useState(0)
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({});
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}/users/${userId}`, data);
            // return response.data;
            console.log(response.data);
            if (response.data) {
                message.success('Profile updated successful.');
                // navigate("/signin");
            }
        } catch (error) {
            return { payload: [] };
        }
    }

    useEffect(() => {
        console.log(user)
        if (user) {
            setValue("username", user.name);
            setValue("email", user.email);
            setUserId(user.id)
        }
    }, [user]);

    return (
        <Form noValidate onSubmit={handleSubmit(onSubmit)} className='col-md-6'>
            <div className='col-md-12'>
                <div className="">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                        <Form.Label>Username *</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Enter your username"
                            autoFocus
                            {...register("username", {
                                required: true
                            })}
                            isInvalid={errors.username} />
                        <Form.Control.Feedback type="invalid">
                            {errors.username && errors.username.type === 'required' && <span role="alert">Please enter an username.</span>}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
            </div>


            <div className='col-md-12 mt-2'>
                <div className="">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                        <Form.Label>Email *</Form.Label>
                        <Form.Control
                            size="sm"
                            type="text"
                            placeholder="Enter your email *"
                            autoFocus
                            {...register("email", {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "invalid email address"
                                }
                            })}
                            isInvalid={errors.email} />
                        <Form.Control.Feedback type="invalid">
                            {errors.email && errors.email.type === 'required' && <span role="alert">Please enter an email.</span>}
                            {errors.email && errors.email.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
            </div>

            <div className='col-md-12 mt-3 text-right'>
                <button type="submit" className="btn btn-primary" >Update profile</button>

            </div>
        </Form>
    )
}

export default ProfileHolder