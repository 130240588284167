import React from 'react'

export const About = () => {
    return (
        <>
            <div className=' shop-background'>
                <h1 className='store-text'>About</h1>
            </div>
            <div className='container pt-5 pb-5'>
                <div className='row'>
                <h3 className='contact-h3 text-center'>WELCOME</h3>

                <p className='empty-msg text-center'> We are Chan Seafood & Veg, who serve customers with best quality produces in perth..</p>
                    <p className='empty-msg text-center'>
                    We started our business early 2000's with our beloved parents , with their courage and hard-work our business was growing up from a small vegetable farm.Then I started to transport the harvest to the city areas with the higher demand from the customers. We became famous throughout Perth within few days after we started our business as we always come with the concept “Customer First”.</p>
                    <p className='empty-msg text-center'>
                    I realised that there is a shortage for seafood in most of the shopping centres. So I started thinking to start selling seafood along with vegetables. Hence, I was able to start 2 stores in Perth’s busiest stores near CBD. Everyone is welcome to shop with us all 7-days including public holidays from 9am-4pm. Our Friendly staff are ready to serve you anytime.

                    </p>
                    {/* <div className='col-md-4'>
                        <div className='sidebar_inner'>
                            <h5 className="widget_title">Shopping Cart</h5>
                            <p className='empty-msg'>No products in the cart.</p>
                        </div>
                    </div> */}
                </div>
                <div className='row pt-4 text-center'>
                    <h3 className='contact-h3'>OUR ADVANTAGES</h3>
                </div>
                <div className='row '>
                    <div className='col-md-3'>
                        <div className='bi-about-1'></div>
                        <h3 className='h5-about'>Responsibly Sourced</h3>
                        <p className='empty-msg text-center'>Responsibly-sourced seafood & vegetables is the key to the modern, safe and profitable fresh produce industry.</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='bi-about-2'></div>
                        <h3 className='h5-about'>Environment</h3>
                        <p className='empty-msg text-center'>The seafood and vegetable industry has a concern over climate impact, and takes responsibility in any case.</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='bi-about-3'></div>
                        <h3 className='h5-about'>Staff</h3>
                        <p className='empty-msg text-center'>Our experts work hard to maintain the quality service for our dear customers exclusively.</p>
                    </div>
                    <div className='col-md-3'>
                        <div className='bi-about-4'></div>
                        <h3 className='h5-about'>Marketplace</h3>
                        <p className='empty-msg text-center'>Our business becomes benefitial by providing the customers with the best.</p>
                    </div>
                </div>
            </div>
        </>
    )
}
