import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { message } from 'antd';

export const Contact = () => {

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({});
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/contact`, data);
            // return response.data;
            console.log(response.data);
            if (response.data) {
                message.success('Your message successful delivered. Thank you for contact with us.');
                reset();
            } else {
                message.error('Error while sending message.');
                reset();
            }
        } catch (error) {
            return { payload: [] };
        }
    }

    return (
        <>
            <div className=' shop-background'>
                <h1 className='store-text'>Contact</h1>
            </div>
            <div className='container pt-5 pb-5'>
            <div className='row'>
                    <div className='col-md-4'>
                        <div className='sidebar_inner text-center'>
                            <FontAwesomeIcon icon={faHouse} size='2x' className='pb-2' />
                            <h5 className="widget_title m-0">Address</h5>
                            <p className='empty-msg'>(Melille Shopping Centre) 
                            Melville, WA 6153</p>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='sidebar_inner text-center'>
                            <FontAwesomeIcon icon={faPhone} size='2x' className='pb-2' />
                            <h5 className="widget_title m-0">PHONE</h5>
                            <p className='empty-msg'> +61452218562 </p>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <div className='sidebar_inner text-center'>
                            <FontAwesomeIcon icon={faEnvelope} size='2x' className='pb-2' />
                            <h5 className="widget_title m-0">EMAIL</h5>
                            <p className='empty-msg'>Chanveg@gmail.com</p>
                        </div>
                    </div>

                </div>


                <div className='row text-center pt-4'>
                    <h4 className='contact-h4'>message</h4>
                    <h3 className='contact-h3'>Drop a Line</h3>
                </div>
                <Form>
                    <div className='row pt-4 contact-container'>

                        <div className='col-md-6'>
                            <div className="">
                                <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                    <Form.Control
                                        size=""
                                        type="text"
                                        placeholder="Enter your name *"
                                        autoFocus
                                        {...register("name", {
                                            required: true
                                        })}
                                        isInvalid={errors.name} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name && errors.name.type === 'required' && <span role="alert">Please enter an name.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                <Form.Control
                                    size=""
                                    type="text"
                                    placeholder="Enter your email *"
                                    autoFocus
                                    {...register("email", {
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address"
                                        }
                                    })}
                                    isInvalid={errors.email} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email && errors.email.type === 'required' && <span role="alert">Please enter an email.</span>}
                                    {errors.email && errors.email.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                        <div className='col-md-12 mt-3'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    {...register("message", {
                                        required: true
                                    })}
                                    isInvalid={errors.message}
                                    placeholder='Enter your message'
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.message && errors.message.type === 'required' && <span role="alert">Please enter a message.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>

                        <div className='col-md-12 mt-3 text-center'>
                            <button href="#" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>SEND MESSAGE</button>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    )
}
