import React, { useEffect, useState } from 'react'
import logo from '../images/logo.jpg'
import { Link, useNavigate } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCartShopping, faBell, faDollar } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { message, Dropdown, Space, Badge, Avatar, Popover, List } from 'antd'
import { DownOutlined, NotificationOutlined } from '@ant-design/icons';
// import { } from 'antd';

export const Header = () => {
    const navigate = useNavigate();

    const [isLogin, setIsLogin] = useState(false);
    const [username, setUsername] = useState('');
    const [cartTotal, setCartTotal] = useState(0);
    const [cartItemCount, setCartItemCount] = useState(0);
    const [items, setItems] = useState([
        {
            label: 'Profile',
            key: '1',
        },
        {
            label: 'Dashboard',
            key: '2',
        },
        {
            label: 'Logout',
            key: '3',
        },
    ]);
    const [notifications, setNotification] = useState([]);
    const [notificationViewed, setNotificationViewed] = useState(false);
    const MINUTE_MS = 6000;

    useEffect(() => {
        // getLocations();
        var isLogin = localStorage.getItem("isLogin");
        var userType = localStorage.getItem("userType");

        if (userType == 3) {
            setItems([
                {
                    label: 'Profile',
                    key: '1',
                },
                {
                    label: 'My Order',
                    key: '4',
                },
                {
                    label: 'Logout',
                    key: '3',
                },
            ])
        } else {

        }

        if (isLogin == 'true') {
            var user = JSON.parse(localStorage.getItem("user"));
            // console.log(user.name);
            setIsLogin(true);
            setUsername(user.name);
            loadCartTotal();
            loadNotification();
        } else {
            setIsLogin(false);
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            loadCartTotal();
            // loadNotification();
            // loadSessions(selectedLocation, searchedVRN, selectedFromDate, selectedToDate, selectedStatus, selectedPage, selectedPageSize);
            // console.log("INTERVEL LOAD :: ", searchedVRN);
        }, MINUTE_MS);
    }, []);

    const loadCartTotal = async () => {
        try {
            var newCartItem = JSON.parse(localStorage.getItem("cart_item"));
            var tot = newCartItem.reduce((accum, item) => accum + (item.price * item.selectedQty), 0);
            setCartItemCount(newCartItem.length);
            // const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/cart/cart-total`);
            //  response.data;
            // console.log(response.data)
            // if (response.data) {
            setCartTotal(tot);
            // }

        } catch (error) {
            // message.error('Error while adding item to cart.');
            return { payload: [] };
        }
    };

    const loadNotification = async () => {
        try {
            var user = JSON.parse(localStorage.getItem("user"));
            const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/notification/${user.id}`);
            //  response.data;
            console.log(response.data)
            if (response.data) {
                setNotification(response.data.data);
            }

        } catch (error) {
            // message.error('Error while adding item to cart.');
            return { payload: [] };
        }
    };


    const logout = () => {
        localStorage.setItem("user", JSON.stringify({}));
        localStorage.setItem("isLogin", false);
        localStorage.removeItem("userType");
        window.location = "/signin";
    }

    const onClick = ({ key }) => {
        // message.info(`Click on item ${key}`);
        if (key == 1) {
            navigate("/profile");
        } else if (key == 2) {
            navigate("/products");
        } else if (key == 3) {
            logout();
        } else if (key == 4) {
            navigate("/my-order");
        }
    };

    const content = () => {
        return (
            <List
                itemLayout="horizontal"
                dataSource={notifications}
                style={{ maxHeight: 300, overflowY: 'auto' }}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar icon={item.type == 'order_notification' ? <FontAwesomeIcon icon={faCartShopping} size='1x' className='pr-2 text-white' /> : <FontAwesomeIcon icon={faDollar} size='1x' className='pr-2 text-white' />} />}
                            title={item.title}
                            description={item.text}
                        />
                    </List.Item>
                )}
            />

        )
    }

    const onMouseEnterPopOver = () => {
        setNotificationViewed(true);
    }

    // const items = ;

    return (
        <section className='top-header'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-2 contact-address d-flex align-items-center justify-content-center'>
                        <span>
                            (MelvilleShopping Centre)<br />
                            Melville, WA</span>
                    </div>
                    <div className='col-md-2 contact-address d-flex align-items-center justify-content-center'>
                        <span>Call us at<br />
                            (04)52218562</span>
                    </div>

                    <div className='col-md-4 '>
                        <img src={logo} className='img-fluid mx-auto d-block logo' />
                    </div>

                    <div className='col-md-2 contact-address d-flex align-items-center justify-content-center'>

                        <FontAwesomeIcon icon={faUser} size='2x' className='pr-2 text-white' /> &nbsp;
                        {isLogin ? <span className='ps-1'>

                            {/* <span to='signin' className='nav-link' onClick={logout}>Logout</span> */}

                            <Dropdown
                                menu={{
                                    items,
                                    onClick,
                                }}
                            >
                                <Link className='nav-link' >
                                    <Space>
                                        {username}
                                        <DownOutlined />
                                    </Space>
                                </Link>
                            </Dropdown>
                        </span> : <span className='ps-1'>

                            <Link to='signin' className='nav-link'>Login</Link>
                            <Link to='signup' className='nav-link'>Register</Link>
                        </span>
                        }
                    </div>
                    <div className='col-md-2 contact-address d-flex align-items-center justify-content-center'>
                        {/* <Space> */}

                        <Link to='cart' className='nav-link'>
                            <Badge count={cartItemCount}>
                                <Avatar shape="square" icon={<FontAwesomeIcon icon={faCartShopping} size='1x' className='pr-2 text-white' />} />
                            </Badge>
                            {/* <FontAwesomeIcon icon={faCartShopping} size='2x' className='pr-2 text-white' /> */}
                        </Link> &nbsp;
                        <span className='ps-1'>
                            <Link to='cart' className='nav-link'>
                                <span className='text-white'>Your cart</span>:</Link>
                            ${cartTotal.toFixed(2)}
                        </span>
                        <Popover placement="bottomRight" title="Notification" content={content} className='ps-2' overlayStyle={{
                            width: 500,
                        }} onMouseLeave={onMouseEnterPopOver}>
                            <Badge dot={!notificationViewed}>
                                <FontAwesomeIcon icon={faBell} size='2x' className='pr-2 text-white' />
                            </Badge>
                        </Popover>
                        {/* <Link to='cart' className='nav-link ps-2'>
                               
                            </Link> */}
                        {/* </Space> */}
                    </div>

                </div>
            </div>
        </section>
    )
}
