
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { message } from 'antd';
import { useEffect, useState } from 'react';

function Sale() {

    const [data, setData] = useState([]);

    useEffect(() => {
        loadCountries();
    }, []);

    const loadCountries = async () => {
        // var sortBy = "";

        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/promotion`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            setData(response.data.payload.data);
        }
    };

    const products = (item) => {
        var it = item.map((product) => {
            return product.label;
        });
        console.log(item);
        return it.slice(0, -1);
    }

    const items = data.map((item => {
        return (
            <div className='col-md-4 mt-3'>
                <div className='sidebar_inner text-center'>
                    {/* <FontAwesomeIcon icon={faHouse} size='2x' className='pb-2' /> */}
                    <h5 className="widget_title m-0">Buy {
                        item.products.map((product) => {
                            return product.label +", ";
                        })
                    } get
                        {item.discount_type == "Percentage" ? item.discount_value + "%" : "$" + item.discount_value.toFixed(2)} off.</h5>
                    <p className='empty-msg'>Promotion start date - {item.start_date}</p>
                    <p className='empty-msg'>Promotion end date - {item.end_date}</p>
                    <p>Get {item.discount_type == "Percentage" ? item.discount_value + "%" : "$" + item.discount_value.toFixed(2)} discount only for this products</p>

                </div>
            </div>
        )
    }))

    return (
        <>
            <div className=' shop-background'>
                <h1 className='store-text'>Promotions</h1>
            </div>
            <div className='container pt-5 pb-5'>
                <div className='row'>
                    {items}
                </div>
            </div>
        </>
    )
}

export default Sale;