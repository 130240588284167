import React from 'react'
import { Link } from 'react-router-dom'

function TermsNCondition() {
    return (
        <>
            <div className=' shop-background'>
                <h1 className='store-text'>Terms & Conditions</h1>
            </div>
            <div className='container pt-5 pb-5'>
                <div className='row'>
                    <ol>
                        <li> Acceptance of Terms</li>

                        By using our website, services, or purchasing products from Chan sea food & veg store, you agree to comply with and be bound by the following terms and conditions. If you do not agree with these terms, please do not use our services or website.

                        <li>Products</li>

                        We offer fresh fish and vegetables for sale through our store.
                        Product availability may vary based on seasonal factors and market conditions.
                        We strive to provide accurate product descriptions and prices, but we do not guarantee the accuracy or completeness of the information provided.
                        <li> Ordering and Payment</li>

                        Orders can be placed through our website or in-person at our physical store.
                        Prices are listed in AUD and are subject to change without notice.
                        Payment methods accepted include paypal.
                        Orders are subject to acceptance by Chan sea food & veg store and may be rejected or canceled at our discretion.
                        <li> Delivery and Pickup</li>

                        We offer delivery and pickup options. Delivery may be subject to additional fees.
                        Delivery times and availability may vary. We will make reasonable efforts to fulfill delivery requests promptly.
                        Customers are responsible for providing accurate delivery information. Failure to do so may result in delays or additional charges.
                        <li> Returns and Refunds</li>

                        We take pride in the quality of our products. If you are not satisfied with your purchase, please contact us within 5 days of receipt.
                        Returns and refunds are subject to our discretion and may be subject to restocking fees.
                        We do not accept returns on perishable items unless they are defective or damaged.
                        <li> Privacy Policy</li>

                        <p>We respect your privacy and are committed to protecting your personal information. Please review our <Link to='/privacy-policy' className='nav-link'>Privacy Policy</Link> for details on how we collect, use, and protect your data.</p>
                        <li> Intellectual Property</li>

                        All content on our website, including text, images, logos, and trademarks, is the property of Chan sea food & veg store and is protected by intellectual property laws.
                        <li> Limitation of Liability</li>

                        Chan sea food & veg store is not responsible for any direct, indirect, incidental, special, or consequential damages arising from the use of our products or services.
                        Our liability is limited to the purchase price of the products.
                        <li> Governing Law</li>

                        These terms and conditions are governed by and construed in accordance with the laws of WA Jurisdiction.
                        Any disputes arising from these terms and conditions will be subject to the exclusive jurisdiction of the courts in WA Jurisdiction.
                        10. Changes to Terms and Conditions

                        Chan sea food & veg store reserves the right to update or modify these terms and conditions at any time. Changes will be effective immediately upon posting on our website.
                        It is your responsibility to review these terms periodically for changes.
                        <li> Contact Information</li>

                        If you have any questions or concerns about these terms and conditions, please contact us at (04)52218562.

                        By using our services or purchasing products from Chan sea food & veg store, you acknowledge that you have read, understood, and agree to these terms and conditions.

                    </ol>
                </div>
            </div>
        </>
    )
}

export default TermsNCondition