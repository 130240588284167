import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom'
import { EyeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd'
import Button from 'react-bootstrap/Button';
import { DesktopOutlined, MessageOutlined, CheckOutlined, CheckCircleFilled, StopFilled, ClockCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faTrash } from '@fortawesome/free-solid-svg-icons'
import { message } from 'antd';
import InvoiceModal from '../InvoiceModal';
import { Modal } from 'react-bootstrap';

export const PickUp = () => {
    const navigate = useNavigate();
    const myRef = useRef();

    const [data, setData] = useState([]);
    const [showSpecialNoteMdl, setShowSpecialNoteMdl] = useState(false);
    const [specialMsg, setSpecialMsg] = useState("");

    useEffect(() => {
        loadOrders();
    }, []);

    const loadOrders = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order?status=1&pickup_method=1`);

        if (response.data) {
            setData(response.data.data);
        }
    };

    const viewInvoice = async (id) => {
        try {
            myRef.current.childMethod(id);
        } catch (error) {
            // return { payload: [] };
        }
    }

    const items = data.map(((item, index) => {

        return (
            <tr key={index}>
                <td scope="row">{index + 1}</td>
                <td>{item.created_at.split(".")[0]}</td>
                <td>
                    {item.pickup_method == '2' ? "Delivery" : "Pickup"}
                </td>
                <td> ${item.sub_total.toFixed(2)}</td>
                <td>{item.delivery_fee.toFixed(2)} </td>
                <td>${item.grand_total.toFixed(2)}</td>
                <td>
                    <div style={{ textAlign: "center" }}>
                        {item.status == 0 ? <Tooltip title="Unpaid"><ExclamationCircleOutlined style={{ fontSize: '18px', color: 'red' }} className='status-border-danger' /></Tooltip> :
                            item.status == 1 ? <Tooltip title="Pending"><ClockCircleFilled style={{ fontSize: '18px', color: '#08c' }} /></Tooltip> :
                                item.status == 2 ? <Tooltip title="Completed"><CheckCircleFilled style={{ fontSize: '18px', color: "#52c41a" }} /></Tooltip> :
                                    item.status == 3 ? <Tooltip title="Cancel"><StopFilled style={{ fontSize: '18px', color: '#ef6062' }} />  </Tooltip> : <></>}
                    </div>
                </td>
                <td>
                    {
                        item.status != 0 ? <Tooltip title="View Invoice">
                            <Button className="btn-alt" size="sm" variant="none" onClick={() => viewInvoice(item.id)}><EyeOutlined style={{ fontSize: '18px', color: '#08c' }} /></Button>
                        </Tooltip> : null
                    } &nbsp;
                    {item.additional_note ?
                        <Tooltip title="View Message">
                            <Button className="btn-alt" size="sm" variant="none" onClick={() => viewMessage(item.additional_note)}><MessageOutlined style={{ fontSize: '18px', color: '#08c' }} /></Button>
                        </Tooltip> : null
                    }
                    <Tooltip title="Complete order">
                        <Button className="btn-alt" size="sm" variant="none" onClick={() => onApprove(item.id)}><CheckOutlined style={{ fontSize: '18px' }} className='text-success' /></Button>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Button className='btn-alt' size="sm" variant="none" onClick={() => { handleDelete(item.id) }}>
                            <FontAwesomeIcon icon={faTrash} className='text-danger' />
                        </Button>
                    </Tooltip>
                </td>
            </tr >
        )
    }))

    const onApprove = async (order_id) => {
        var data1 = { status: 2 };

        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/order/${order_id}`, data1);
        if (response.data) {
            message.success('Pickup successful.');
            await loadOrders();
        } else {
            message.error('Error while saving order message.');
        }
    }

    const handleDelete = async data => {
        const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/order/${data}`);
        //  response.data;
        // console.log(response.data)
        if (response.data) {
            message.success('Order deleted successful.');
            loadOrders();
        }
    }

    const viewMessage = (message) => {

        setSpecialMsg(message);
        setShowSpecialNoteMdl(true);
    }

    return (
        <>
            <InvoiceModal ref={myRef} modelTitle="Permit Parking" >
            </InvoiceModal>
            <Modal show={showSpecialNoteMdl} onHide={() => setShowSpecialNoteMdl(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Special Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {specialMsg}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowSpecialNoteMdl(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className='container pt-5 pb-5'>
                {data.length > 0 ?
                    <>
                        <div className='row'>
                            <table className="table cart-item-tbl">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Method</th>
                                        <th scope="col">Sub Total</th>
                                        <th scope="col">Delivery Fee</th>
                                        <th scope="col">Total</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items}
                                </tbody>
                            </table>
                        </div>
                    </> : <div className='row text-center'>
                        {/* <FontAwesomeIcon icon={faCartShopping} size='10x' className=' mb-3' /> */}
                        <h2>No Data!</h2>
                        <p>There is no data to show as pending order.</p>
                        {/* <div><Button onClick={onClickBackToShop} type="primary" danger>RETURN TO SHOP</Button></div> */}
                    </div>
                }
            </div>
        </>
    )
}
