import React from 'react'
import logo from '../images/logo.jpg'
import { Link } from 'react-router-dom'

export const Footer = () => {
    return (
        <section className='footer-background'>
            <div className='container '>
                <div className='row pt-4 pb-3'>
                    <div className='col-md-3'>
                        <img src={logo} className='logo'alt=''/>
                    </div>

                    <div className='col-md-3'>
                        <h5 className='footer-heading'>ABOUT US</h5>
                        <p className='contact-address'>For the best seafood, vegtables and service you can choose our shop. We guarantee you the freshest seafood prepared to your liking.</p>
                    </div>

                    <div className='col-md-3'>
                        <h5 className='footer-heading'>Links</h5>
                        <Link to="/terms_n_conditions" className="nav-link text-white" >Terms & Conditions</Link>
                        <Link to="/privacy-policy" className="nav-link text-white" >Privacy Policy</Link>
                        <Link to="/contact" className="nav-link text-white">Contact Us</Link>

                    </div>

                    <div className='col-md-3'>
                        <h5 className='footer-heading'>CONTACT US</h5>
                        <p className='contact-address'>
                            Call us at (04)52218562<br />
                            MelvilleShopping Centre,Melville, WA 6156<br />
                            Chanveg@gmail.com
                        </p>
                    </div>

                </div>
            </div>
        </section>
    )
}
