import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Button, Modal } from 'antd';
import Invoice from '../common/Invoice';

const InvoiceModal = forwardRef(({ children, modelTitle, countryAddedSuccess }, ref) => {
    const [open, setOpen] = useState(false);
    const [countryId, setCountryId] = useState(0);

    useImperativeHandle(ref, () => ({
        childMethod(id) {
            showDrawer();
            setCountryId(id);
            // reset();
            if (id !== 0) {
                // loadParkingPermit(id);
            } else {

            }
        },

        childMethod2() {
            onClose();
        }
    }), [countryId]);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Modal
            title=""
            centered
            open={open}
            // onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            width={1000}
            okButtonProps={{
                disabled: true,
              }}
        >
             <Invoice orderId={ countryId} />
        </Modal>
    )

})
export default InvoiceModal;