import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
// import Pagination from 'react-bootstrap/Pagination';
import { message, Pagination } from 'antd';



export const Shop = () => {
    const [data, setData] = useState([]);
    const [cartData, setCartData] = useState([]);

    const [categories, setCategories] = useState(['Clams']);
    const [tags, setTags] = useState(['delicious', 'fish', 'fresh', 'healthy', 'tender']);
    const [sortBy, setSortBy] = useState(0);
    const [range, setRange] = useState(100);
    const [noOfPages, setNoOfPages] = useState(0);
    const [noOfResult, setNoOfResult] = useState(0);
    const [from, setFrom] = useState(0);
    const [to, setTo] = useState(0);
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    useEffect(() => {
        loadCountries(0, 100, 1);
        // loadCart();
        if (localStorage.getItem("cart_item") !== null) {
            var newCartItem = JSON.parse(localStorage.getItem("cart_item"))
            setCartData(newCartItem);
        }
    }, []);

    const loadCountries = async (sortBy, priceRange, page) => {
        // var sortBy = "";

        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/products?sortBy=${sortBy}&priceRange=${priceRange}&page=${page}`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            console.log(response.data.payload.data.last_page);
            setData(response.data.payload.data.data);
            setNoOfPages(response.data.payload.data.last_page);
            setNoOfResult(response.data.payload.data.total);
            setFrom(response.data.payload.data.from);
            setTo(response.data.payload.data.to);
            setPageSize(response.data.payload.data.per_page);
        }
    };

    const addToCard = async (data) => {
        try {
            console.log(data);
            data.selectedQty = 1;
            // var user = JSON.parse(localStorage.getItem("user"));

            if (localStorage.getItem("cart_item") === null) {
                //...
                var cartItem = [];

                cartItem.push(data);
                localStorage.setItem("cart_item", JSON.stringify(cartItem));
            } else {
                var cartItem = JSON.parse(localStorage.getItem("cart_item"));

                const index = cartItem.findIndex(item => item.id === data.id);

                if (index !== -1) {
                    // Update the existing object
                    cartItem[index] = { ...cartItem[index], selectedQty: cartItem[index].selectedQty + 1 };
                } else {
                    // Push the new object to the array
                    cartItem.push(data);
                }
                localStorage.setItem("cart_item", JSON.stringify(cartItem));
            }
            var newCartItem = JSON.parse(localStorage.getItem("cart_item"));
            setCartData(newCartItem);
            message.success('Item added to cart.');

            // var data = {
            //     product_id: productId,
            //     quantity: 1
            // }


            // const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/cart`, data);
            // return response.data;
            // console.log(response.data);
            // if (response.data) {
            //     message.success('Item added to cart.');
            //     loadCountries(sortBy, range);
            //     loadCart();
            // }
        } catch (error) {
            message.error('Error while adding item to cart.');
            return { payload: [] };
        }
    }

    const items = data.map((item => {
        return (
            <div className='col-md-4 mb-2' key={item.id}>
                <div className="card text-center card-alt" >
                    <img className="card-img-top" src={`${process.env.REACT_APP_API_ENDPOINT_STORAGE}/${item.image}`} alt="Card image" />
                    <div className="card-body">
                        <h4 className="card-title">{item.name}</h4>
                        <p className="card-text">{item.description}</p>
                        <p className="card-text text-danger">Price : $ {item.price.toFixed(2)}</p>
                        <p className="card-text">SOH : {item.quantity}</p>
                        {
                            item.quantity == 0 ? <button className="btn btn-danger">Out of Stock</button> : <button onClick={() => addToCard(item)} className="btn btn-primary">Add to cart</button>
                        }

                    </div>
                </div>
            </div>)
    }))

    const onChangeSort = e => {
        console.log(e.target.value);
        setSortBy(e.target.value)
        loadCountries(e.target.value, range, 1);
    }

    const onChangeRange = e => {
        setRange(e.target.value);
        // console.log(e.target.value);
        loadCountries(sortBy, e.target.value, 1);
    }

    const onChangePaging = (page, pageSize) => {
        console.log(page);
        setCurrent(page);
        loadCountries(sortBy, range, page);

    }

    // let active = 1;
    // let itemsPage = [];
    // for (let number = 1; number <= noOfPages; number++) {
    //     itemsPage.push(
    //         <Pagination.Item key={number} active={number === active} onClick={onClickPagination(number)}>
    //             {number}
    //         </Pagination.Item>,
    //     );
    // }

    // const loadCart = async () => {
    //     const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/cart`);
    //     //  response.data;
    //     console.log(response.data)
    //     if (response.data) {
    //         setCartData(response.data.payload.data);
    //     }
    // };

    const itemsCart = cartData.map(((item, index) => {

        // var subTotal1 = item.quantity * item.product.price;
        //var net = subTotal + subTotal1;
        // setSubtotal(net);
        return (
            <tr key={index}>
                <td>
                    <img className="cart-thumb" src={`${process.env.REACT_APP_API_ENDPOINT_STORAGE}/${item.image}`} alt="Card image" />
                </td>
                <td>
                    <h5 className='mb-0'>{item.name}</h5>
                    <p className='fs-6 text-body-tertiary mb-1'> {item.selectedQty} x ${item.price.toFixed(2)}  </p>
                </td>
            </tr>
        )
    }))

    return (
        <>
            <div className=' shop-background'>
                <h1 className='store-text'>Sea food & Veg Store</h1>
            </div>
            <div className='container pt-5 pb-5'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='sidebar_inner'>
                            <h5 className="widget_title">Shopping Cart</h5>
                            {cartData.length === 0 ? <p className='empty-msg'>No products in the cart.</p> : null}

                            <div className='row'>
                                <table className="">
                                    <thead>
                                    </thead>
                                    <tbody>
                                        {itemsCart}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className='sidebar_inner mt-3'>
                            <h5 className="widget_title">PRICE FILTER</h5>
                            <Form.Label>Range : 0 - ${range}</Form.Label>
                            <Form.Range value={range} onChange={onChangeRange} />
                        </div>

                        <div className='sidebar_inner mt-3'>
                            <h5 className="widget_title">CATEGORIES</h5>
                            {categories.map(item => { return (<a href='#' className='empty-msg link'> {item}</a>) })}
                        </div>

                        <div className='sidebar_inner mt-3'>
                            <h5 className="widget_title">Tags</h5>
                            {tags.map(item => { return (<a href='#' className='empty-msg link'> {item}</a>) })}
                        </div>

                    </div>
                    <div className='col-md-9'>
                        <div className='row'>
                            <div className="card card-alt">
                                <div className="card-body">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h6>Showing {from} – {to} of {noOfResult} results</h6>
                                        <div>
                                            <select className="form-select" aria-label="Default select example" onChange={onChangeSort}>
                                                <option selected value="0">Default sorting </option>
                                                {/* <option value="1">Sort by popularity</option>
                                                <option value="2">Sort by average rating</option> */}
                                                <option value="3">Sort by latest</option>
                                                <option value="4">Sort by price: low to high</option>
                                                <option value="5">Sort by price: high to low</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-5'>
                            {items}
                        </div>
                        <div className='d-flex justify-content-center pt-4'>
                            <Pagination size="small" current={current} defaultCurrent={1} total={noOfResult} showSizeChanger={false} onChange={(page, pageSize) => onChangePaging(page, pageSize)} pageSizeOptions={[12, 24, 48]} defaultPageSize={pageSize} />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
