import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage2 from '../images/slider-2.jpg';
import ExampleCarouselImage3 from '../images/slider-3.jpg';
import ExampleCarouselImage from '../images/slider-4.png';

export const ImageSlider = () => {
    return (
        <Carousel className='h-50'>
            <Carousel.Item>
                <img
                    className="d-block w-100 h-50"
                    src={ExampleCarouselImage}
                    alt="First slide"
                />
                {/* <ExampleCarouselImage text="First slide" /> */}
                <Carousel.Caption>
                <h3>OCEAN VARIETY, VEGETABLES</h3>
                    <p>IN OUR STORE</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                {/* <ExampleCarouselImage text="Second slide" /> */}
                <img
                    className="d-block w-100"
                    src={ExampleCarouselImage2}
                    alt="First slide"
                />
                <Carousel.Caption>
                <h3>THE WONDERS OF THE OCEAN</h3>
                    <p>ON YOUR PLATE.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                {/* <ExampleCarouselImage text="Third slide" /> */}
                <img
                    className="d-block w-100"
                    src={ExampleCarouselImage3}
                    alt="First slide"
                />
                <Carousel.Caption>
                <h3>THE PLACE TO BE FOR SEA FOOD & VEG</h3>
                    <p>AT OUR SHOP.</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    )
}
