import React from 'react'
import { ImageSlider } from '../common/ImageSlider'
import WelcomeImage from '../images/bg_welcome.jpg'
import { useNavigate } from "react-router-dom";

export const Home = () => {
    const navigate = useNavigate();
    const learnMore = () => {
        navigate("/about-us");
    }

    return (
        <div>
            <ImageSlider />

            <section className='background-color'>
                <div className='container pt-3 pb-3'>
                    <div className='row m-b-l'>
                        <h6 style={{ color: '#bf5025', textAlign: 'center' }}>welcome</h6>
                        <h2 style={{ color: '#000', textAlign: 'center' }}>SEAFOOD COMPANY</h2>

                    </div>
                    <div className='row m-b-l d-flex justify-content-center'>
                        <div className='col-md-4 bg-blue p-5'>
                            <h2 className='f-color'>Our Seafood</h2>
                            <p className='f-color'>Fish markets range in size from small fish stalls, such as the one in the photo at the right, to the great Tsukiji fish market in Tokyo, turning over about 660,000 tonnes a year. The term can refer to the process.</p>
                            <button type="button" className="btn btn-info f-color" onClick={learnMore}>LEARN MORE</button>
                        </div>
                        <div className='col-md-6'>
                            <img src={WelcomeImage} />
                        </div>
                    </div>
                </div>
            </section>

            
            <div className='container'>
                <div className='row m-b-l'>
                    <div className='col-md-3'><div className='bi-1'></div></div>
                    <div className='col-md-3'><div className='bi-2'></div></div>
                    <div className='col-md-3'><div className='bi-3'></div></div>
                    <div className='col-md-3'><div className='bi-4'></div></div>
                </div>
            </div>
        </div>
    )
}
