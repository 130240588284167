import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function AdminPanel() {
    let userType = localStorage.getItem("userType");
    return (
        <>
            <Nav defaultActiveKey="/products" className="flex-column">
                <Nav.Link as={Link} to="/products">Products</Nav.Link>
                {userType == 1 ? <Nav.Link as={Link} to="/users">Users</Nav.Link> : null}
                <Nav.Link as={Link} to="/contact-messages">Contact Messages</Nav.Link>
                <Nav.Link as={Link} to="/pending-order">Pending Orders</Nav.Link>
                <Nav.Link as={Link} to="/completed-order">Completed Orders</Nav.Link>
                <Nav.Link as={Link} to="/promotion">Promotions</Nav.Link>
            </Nav>
        </>
    )
}
