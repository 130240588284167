import { message } from 'antd';
import axios from 'axios';
import React from 'react'
import { Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const Register = () => {
    const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm({});
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        data.userType = 3; // Customer
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/register`, data);
          
            if (response.data) {
                message.success('User created successful.');
                navigate("/signin");
            } else {
                message.error('Error while creating user.');
            }
        } catch (error) {
            // console.log(Object.keys(error), error.response.data.payload); 
            error.response.data.payload.error.map((item) => {
                message.error(item);
            });
            return { payload: [] };
        }
    }

    return (
        <div className='container mb-5  pb-5'>
            <div className='row text-center pt-4'>
                <h4 className='contact-h4'>Sign up</h4>
                <h3 className='contact-h3'>Create your free account with us</h3>
            </div>
            <div className='row pt-2 contact-container'>
                {/* <Form noValidate onSubmit={handleSubmit(onSubmit)}> */}
                <div className='col-md-6'>
                    <div className="">
                        {/* <label for="exampleInputEmail1" className="form-label">Email address</label> */}
                        {/* <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Enter your username *' /> */}
                        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                            {/* <Form.Label>Previous Password *</Form.Label> */}
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder="Enter your username"
                                autoFocus
                                {...register("username", {
                                    required: true
                                })}
                                isInvalid={errors.username} />
                            <Form.Control.Feedback type="invalid">
                                {errors.username && errors.username.type === 'required' && <span role="alert">Please enter an username.</span>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                            {/* <Form.Label>Previous Password *</Form.Label> */}
                            <Form.Control
                                size="sm"
                                type="password"
                                placeholder="Enter your password *"
                                
                                {...register("password", {
                                    required: true,
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                        message: "invalid Password"
                                    }
                                })}
                                isInvalid={errors.password} />
                            <Form.Control.Feedback type="invalid">
                                {errors.password && errors.password.type === 'required' && <span role="alert">Please enter an password.</span>}
                                {errors.password && errors.password.type === 'pattern' && <span role="alert">Password must contain minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&#).</span>}

                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>

                <div className='col-md-6 mt-2'>
                    <div className="">
                        <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder="Enter your email *"
                                
                                {...register("email", {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "invalid email address"
                                    }
                                })}
                                isInvalid={errors.email} />
                            <Form.Control.Feedback type="invalid">
                                {errors.email && errors.email.type === 'required' && <span role="alert">Please enter an email.</span>}
                                {errors.email && errors.email.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className='col-md-6 mt-2'>
                    <div className="">
                        {/* <label for="exampleInputEmail1" className="form-label">Email address</label> */}
                        {/* <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder='Enter your confirm password *' /> */}
                        {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                            {/* <Form.Label>Previous Password *</Form.Label> */}
                            <Form.Control
                                size="sm"
                                type="password"
                                placeholder="Enter your confirm password *"
                                
                                {...register("confirmPassword", {
                                    validate: (val) => {
                                        if (watch('password') !== val) {
                                            return "Your passwords do no match";
                                        }
                                    }
                                })}
                                isInvalid={errors.confirmPassword} />
                            <Form.Control.Feedback type="invalid">
                                {errors.confirmPassword && <span role="alert">Repeat password does not match..</span>}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>

                <div className='col-md-12 mt-3 text-center'>
                    <button href="#" className="btn btn-primary" onClick={handleSubmit(onSubmit)}>Sign up</button>

                </div>
                {/* </Form> */}
            </div>
        </div>
    )
}
