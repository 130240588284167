import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import ProfileHolder from './ProfileHolder';
import PasswordHolder from './PasswordHolder';


function Profile() {
    const [user, setUser] = useState();

    useEffect(() => {
        // getLocations();
        var isLogin = localStorage.getItem("isLogin");
        if (isLogin == 'true') {
            var userJson = JSON.parse(localStorage.getItem("user"));
            console.log(userJson);
            // setIsLogin(true);
            setUser(userJson);
        } else {
            // setIsLogin(false);
        }
    }, []);

    return (
        <div className='container mb-5  pb-5'>
            <div className='row text-center pt-4'>
                <h4 className='contact-h4'>Profile</h4>
                <h3 className='contact-h3'>You can edit your account details.</h3>
                <FontAwesomeIcon icon={faUser} size='4x' className='pb-2 ' />
            </div>
            <div className='row pt-2 contact-container'>
                <ProfileHolder user={ user } />

                <PasswordHolder user={ user }/>
            </div>
        </div>
    )
}

export default Profile