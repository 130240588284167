import React from 'react'
import { Link } from "react-router-dom";

export const Navigation = () => {
    return (
        <nav className="navbar navbar-expand-sm bg-light justify-content-center">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <Link to="/" className="nav-link text-uppercase" >HOME</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-uppercase" to="shop">SHOP</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link text-uppercase" to="sale">SALE</Link>
                </li> 
                <li className="nav-item">
                    <Link className="nav-link text-uppercase" to="about-us">ABOUT US</Link>
                </li>
                
            </ul>
        </nav>
    )
}
