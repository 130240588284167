import { Button, Result } from 'antd'
import React from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import Invoice from '../common/Invoice';

export default function OrderSuccess() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const onClickBack = () => {
        navigate("/shop");
    }
    return (
        <>
            <Result
                status="success"
                title="Successfully Purchased the Items!"
                subTitle={searchParams.get('pickup_method') == 2 ? `Order number: ${searchParams.get('order_id')} , Your order will deliver to your address with in two days.`
                    : `Order number: ${searchParams.get('order_id')} , Your can visit our location and pickup the order.`}
                extra={[
                    <Button type="primary" key="console" onClick={onClickBack}>
                        Go Back to Shop
                    </Button>
                ]}
            />
            <Invoice orderId={ searchParams.get('order_id')} />
        </>
    )
}
