import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { Divider, message } from 'antd';
import { Avatar, Segmented, Space } from 'antd';
import pickup from '../images/pick-up-car.png'
import delivery from '../images/delivery.png'

const Checkout = () => {

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({});
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [subTotal, setSubtotal] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [pickupMethod, setPickupMethod] = useState('pickup');
    const [promoDiscountEligible, setPromoDiscountEligible] = useState(false);
    const [promoDiscountData, setPromoDiscountData] = useState({});
    const [discountedAmount, setDiscountedAmount] = useState(0);

    useEffect(() => {
        loadCartItem();
    }, []);

    const onSubmit = async (data) => {
        var user = JSON.parse(localStorage.getItem("user"));

        data.pickup_method = pickupMethod === 'delivery' ? 2 : 1;
        data.sub_total = subTotal;
        data.delivery_fee = pickupMethod === 'delivery' ? 15 : 0;
        data.grand_total = grandTotal;
        data.user_id = user.id;

        data.promo_eligibility = promoDiscountEligible;
        data.promo_discounted_value = discountedAmount;

        data.promotion_id = promoDiscountEligible ? promoDiscountData.id : 0;

        console.log(data);
        // return;
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/order`, data);
            // return response.data;
            console.log(response.data);
            if (response.data) {
                var orderId = response.data.data.id;
                message.success('Your order is saved.');
                navigate(`/payment/${orderId}`);
                // reset();
            } else {
                message.error('Error while save the checkout.');
                // reset();
            }
        } catch (error) {
            message.error('Error while save the checkout.');
            return { payload: [] };
        }
    }

    const items = data.map(((item, index) => {
        var subTotal1 = item.selectedQty * item.price;

        return (
            <tr key={index}>
                <td>{item.name} x {item.selectedQty}</td>
                <td>${subTotal1.toFixed(2)}</td>
            </tr>
        )
    }))

    const loadCartItem = async () => {
        var newCartItem = JSON.parse(localStorage.getItem("cart_item"));
        var productIds = newCartItem.map(item => {
            return item.id;
        })

        var data = { productIds: productIds }

        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/promotion/get-promo`, data);

        var tot = newCartItem.reduce((accum, item) => accum + (item.price * item.selectedQty), 0);
        setSubtotal(tot);

        console.log(productIds);
        setData(newCartItem);
        setGrandTotal(tot);

        if (response.data) {
            if (response.data.payload.status) {
                setPromoDiscountEligible(true);
                setPromoDiscountData(response.data.payload.data);

                var discountDetail = response.data.payload.data;
                var discountedAmount = 0;
                if (discountDetail.discount_type == "Percentage") {
                    discountedAmount = (tot * discountDetail.discount_value / 100);
                } else {
                    discountedAmount = discountDetail.discount_value;
                }

                setDiscountedAmount(discountedAmount);

                setGrandTotal(tot - discountedAmount);
            }
        }


    };

    const onChangePickup = e => {
        console.log(e);
        setPickupMethod(e);
        var gt = 0;
        if (e === 'delivery') {
            // if(PromoDiscountEligible)

            gt = subTotal + 15;
        } else {
            gt = subTotal;
        }

        if (promoDiscountEligible) {
            gt = gt - discountedAmount;
        }

        setGrandTotal(gt);
    }

    const serviceFee = () => {
        if (pickupMethod === 'delivery') {
            return (
                <tr>
                    <td></td>
                    <td>Delivery fee $ 15.00</td>
                </tr>
            )
        }
    }

    const promoDiscount = () => {
        if (promoDiscountEligible) {
            return (
                <tr>
                    <td></td>
                    <td>Promo Discount  $ {discountedAmount.toFixed(2)}</td>
                </tr>
            )
        }
    }

    return (
        <>
            <div className=' shop-background'>
                <h1 className='store-text'>CHECKOUT</h1>
            </div>
            <div className='container pt-4 pb-5'>

                <Form>
                    <div className='row pt-4 contact-container'>

                        <div className='col-md-6'>
                            <h3 className='contact-h3 mt-4 mb-4'>BILLING DETAILS</h3>
                            <div className='row'>
                                <Form.Group className="mb-3 col-6" controlId="exampleForm.ControlPreviousPassword">
                                    <Form.Label>FIRST NAME  <span className="label-danger">*</span> </Form.Label>
                                    <Form.Control
                                        size=""
                                        type="text"

                                        {...register("first_name", {
                                            required: true
                                        })}
                                        isInvalid={errors.first_name} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.first_name && errors.first_name.type === 'required' && <span role="alert">Please enter a first name.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3 col-6" controlId="exampleForm.ControlPreviousPassword">
                                    <Form.Label>LAST NAME  <span className="label-danger">*</span> </Form.Label>
                                    <Form.Control
                                        size=""
                                        type="text"
                                        placeholder=""

                                        {...register("last_name", {
                                            required: true
                                        })}
                                        isInvalid={errors.last_name} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.last_name && errors.last_name.type === 'required' && <span role="alert">Please enter a last name.</span>}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                <Form.Label>COMPANY NAME (OPTIONAL)  <span className="label-danger"></span> </Form.Label>
                                <Form.Control
                                    size=""
                                    type="text"
                                    placeholder=""

                                    {...register("company_name", {
                                    })}
                                    isInvalid={errors.company_name} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.company_name && errors.company_name.type === 'required' && <span role="alert">Please enter a company name.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                <Form.Label>STREET ADDRESS  <span className="label-danger">*</span> </Form.Label>
                                <Form.Control
                                    size=""
                                    type="text"
                                    placeholder="House number and street name"

                                    {...register("street_address", {
                                        required: true
                                    })}
                                    isInvalid={errors.street_address} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.street_address && errors.street_address.type === 'required' && <span role="alert">Please enter a street address.</span>}
                                    {errors.street_address && errors.street_address.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                {/* <Form.Label>STREET ADDRESS  <span className="label-danger">*</span> </Form.Label> */}
                                <Form.Control
                                    size=""
                                    type="text"
                                    placeholder="Apartment, suite, unit etc (optional)"

                                    {...register("apartment_address", {
                                    })}
                                    isInvalid={errors.apartment_address} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.apartment_address && errors.apartment_address.type === 'required' && <span role="alert">Please enter an email.</span>}
                                    {errors.apartment_address && errors.apartment_address.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                <Form.Label>TOWN / CITY  <span className="label-danger">*</span> </Form.Label>
                                <Form.Control
                                    size=""
                                    type="text"
                                    placeholder=""

                                    {...register("town", {
                                        required: true
                                    })}
                                    isInvalid={errors.town} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.town && errors.town.type === 'required' && <span role="alert">Please enter a town.</span>}
                                    {errors.town && errors.town.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                <Form.Label>POSTCODE / ZIP <span className="label-danger">*</span> </Form.Label>
                                <Form.Control
                                    size=""
                                    type="text"
                                    placeholder=""

                                    {...register("postal_code", {
                                        required: true
                                    })}
                                    isInvalid={errors.postal_code} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.postal_code && errors.postal_code.type === 'required' && <span role="alert">Please enter a postal code.</span>}
                                    {errors.postal_code && errors.postal_code.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                <Form.Label>PHONE <span className="label-danger">*</span> </Form.Label>
                                <Form.Control
                                    size=""
                                    type="text"
                                    placeholder=""

                                    {...register("phone_no", {
                                        required: true
                                    })}
                                    isInvalid={errors.phone_no} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.phone_no && errors.phone_no.type === 'required' && <span role="alert">Please enter a phone number.</span>}
                                    {errors.phone_no && errors.phone_no.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlPreviousPassword">
                                <Form.Label>EMAIL ADDRESS <span className="label-danger">*</span> </Form.Label>
                                <Form.Control
                                    size=""
                                    type="text"
                                    placeholder=""

                                    {...register("email", {
                                        required: true,
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address"
                                        }
                                    })}
                                    isInvalid={errors.email} />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email && errors.email.type === 'required' && <span role="alert">Please enter an email.</span>}
                                    {errors.email && errors.email.type === 'pattern' && <span role="alert">Please enter a valid email.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>

                        </div>
                        <div className='col-md-6'>
                            <h3 className='contact-h3 mt-4 mb-4'>ADDITIONAL INFORMATION</h3>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>ORDER NOTES (OPTIONAL) <span className="label-danger"></span> </Form.Label>

                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    {...register("additional_note", {
                                    })}
                                    isInvalid={errors.additional_note}
                                    placeholder='Note about your order. e.g: special note for delivery'
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.additional_note && errors.additional_note.type === 'required' && <span role="alert">Please enter a message.</span>}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <Divider />

                        <div className='col-md-12 mt-3 mb-4'>
                            <h3 className='contact-h3 mt-4 mb-4'>Method</h3>
                            <Segmented
                                onChange={onChangePickup}
                                options={[
                                    {
                                        label: (
                                            <div
                                                style={{
                                                    padding: 4,
                                                }}
                                                size='large'

                                            >
                                                <Avatar src={pickup} size={'large'} />
                                                <h3>PickUp</h3>
                                                <p>You can collect your order from our melville store any time between 9am-4pm.</p>
                                            </div>
                                        ),
                                        value: 'pickup',
                                    },
                                    {
                                        label: (
                                            <div
                                                style={{
                                                    padding: 4,
                                                }}
                                            >
                                                <Avatar
                                                    style={{
                                                        // backgroundColor: '#f56a00',
                                                    }}
                                                    src={delivery}
                                                    size='large'
                                                />
                                                <h3>Delivery</h3>
                                                <p>You can use delivery service.it will charge additional $15 and will be delivered within 1 business days.</p>
                                            </div>
                                        ),
                                        value: 'delivery',
                                    }
                                ]}
                            />
                        </div>
                        <Divider />

                        <div className='col-md-12 mt-2'>
                            <h3 className='contact-h3 mt-4 mb-4'>YOUR ORDER</h3>
                            <table className='tbl-product-checkout'>
                                <thead>
                                    <tr>
                                        <td className='tbl-product-th'>Product</td>
                                        <td className='tbl-product-th'>Subtotal</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items}
                                    <tr>
                                        <td></td>
                                        <td><span className='text-dark'>Sub Total</span>${subTotal.toFixed(2)}</td>
                                    </tr>
                                    {serviceFee()}
                                    {promoDiscount()}
                                    <tr>
                                        <td></td>
                                        <td><span className='text-dark'>Total</span> ${grandTotal.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className='col-md-12 mt-3 text-end'>
                            <button href="#" className="btn btn-dark text-uppercase" onClick={handleSubmit(onSubmit)}>Checkout</button>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default Checkout;
