import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Divider } from 'antd';

export default function Invoice({ orderId }) {
    const [data, setData] = useState([]);
    // const [subTotal, setSubtotal] = useState(0);

    useEffect(() => {
        loadCartItem();
    }, [orderId]);

    const loadCartItem = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/order/${orderId}`);
        //  response.data;
        console.log(response.data)
        if (response.data) {
            setData(response.data.data);
            // setTotal(response.data.data.grand_total);
        }
    };

    function PrintElem() {
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head><title>' + document.title + '</title>');
        mywindow.document.write('</head><body >');
        mywindow.document.write('<h1>' + document.title + '</h1>');
        mywindow.document.write(document.getElementById("invoice").innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        // mywindow.close();

        return true;
    }

    const items = data?.cart_items?.map(((item, index) => {

        var subTotal1 = item.quantity * item.product.price;
        return (
            <tr key={index}>
                <td scope="row">{index + 1}</td>
                <td className='text-start'>{item.product.name}</td>
                {/* <td>
                    <img className="cart-thumb" src={`${process.env.REACT_APP_API_ENDPOINT_STORAGE}/${item.image}`} alt="Card image" />
                </td> */}
                <td className='text-end'> ${item.product.price.toFixed(2)}</td>
                <td className='text-end'>{item.quantity} </td>
                <td className='text-end'>${subTotal1.toFixed(2)}</td>
                {/* <td><button className="btn btn-danger" onClick={() => deleteItem(item.id)}>x</button></td> */}
            </tr>
        )
    }))

    return (
        <>
            <div className='container border mb-4' id="invoice">
                <div className='row bg-danger text-white p-4'>
                    <div className='col-md-6'>
                        <h1>Invoice</h1>
                    </div>
                    <div className='col-md-3'>
                        <p>(04)52218562
                            <br />Chanveg@gmail.com
                            <br />Chanveg.com</p>
                    </div>
                    <div className='col-md-3'>
                        <p>MelvilleShopping Centre,<br />Melville,<br /> WA 6156</p>
                    </div>
                </div>

                <div className='row  p-4'>
                    <div className='col-md-3'>
                        <p className='text-body-tertiary'>Billed To</p>
                        <p>{data?.first_name} {data?.last_name}
                            <br />{data?.apartment_address} ,{data?.street_address}
                            <br />{data?.town}
                            <br />{data?.postal_code}</p>
                    </div>
                    <div className='col-md-3'>
                        <p className='text-body-tertiary'>Invoice No</p><p> <span className="text-dark">{data?.id}</span></p>
                        {/* <p></p> */}
                        <p className='text-body-tertiary'>Date Of Issues<br /> <span className="text-dark">{data?.created_at?.split("T")[0]}</span></p>
                    </div>
                    <div className='col-md-6 text-end'>
                        <p className='text-body-tertiary'>Invoice total</p>
                        <h1 className='text-danger'>$ {data?.grand_total?.toFixed(2)}</h1>
                    </div>
                </div>
                <Divider />
                <div className='row'>
                    <table className="table cart-item-tbl1">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col" className='text-start'>Product</th>
                                {/* <th></th> */}
                                <th scope="col" className='text-end'>Price</th>
                                <th scope="col" className='text-end'>Quantity</th>
                                <th scope="col" className='text-end'>Sub Total</th>
                                {/* <th></th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {items}
                        </tbody>
                    </table>
                </div>
                <div className='d-flex justify-content-end pt-0  mr-auto '>
                    <div className='total-container'>
                        {/* <h2>CART TOTALS &nbsp;</h2> */}
                        <table className="table sub-total-table" >
                            <tbody>
                                <tr>
                                    <th scope="col">SubTotal</th>
                                    <th scope="col" className='text-end'>$ {data?.sub_total?.toFixed(2)}</th>
                                </tr>
                                {
                                    data?.pickup_method == 2 ? <tr className=''>
                                        <td className='text-muted'>
                                            Delivery Fee
                                        </td>
                                        <td className='text-end text-primary'>$ {data?.delivery_fee?.toFixed(2)}</td>
                                    </tr> : null
                                }

                                {
                                    data?.promo_eligibility ? <tr className='line'>
                                        <td className='text-muted '>
                                            Promotion Discount
                                        </td>
                                        <td className='text-end text-primary'>$ {data?.promo_discounted_value?.toFixed(2)}</td>
                                    </tr> : null
                                }
                                <tr>
                                    <th scope="col">Total</th>
                                    <th scope="col" className='text-end'>$ {data?.grand_total?.toFixed(2)}</th>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div><button className="btn btn-dark" onClick={onClickProceed}>Proceed to checkout</button></div> */}
                    </div>
                </div>
            </div>
            <div><button className="btn btn-dark" onClick={PrintElem}>Print</button></div>

        </>
    )
}
